html {
    scroll-behavior: smooth;
    text-rendering: geometricPrecision;
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
    main {
    }
    section {
        &.intro {
            padding: 0;
        }
        position: relative;
        padding-top: $section-padding-top-mobile;
        padding-bottom: $section-padding-bottom-mobile;
        @media (min-width: $mobile-limit) {
            padding-top: $section-padding-top;
            padding-bottom: $section-padding-bottom;
        }

        &.discover {
            padding-top: 60px !important;
            padding-bottom: $section-padding-bottom-mobile;
            @media (min-width: $mobile-limit) {
                padding-top: 60px !important;
                padding-bottom: $section-padding-bottom;
            }
        }

        &.bg-grey {
            background-color: $bg-grey;
        }
        &.bg-light-grey {
            background-color: $bg-light-grey;
        }
        &.bg-dark-grey {
            background-color: $bg-dark-grey;
        }
        &.bg-gradient-grey {
            background: $bg-gradient-grey;
            blockquote p::before {
                opacity: 0.5;
            }
        }
        &.bg-radial-gradient {
            background: $bg-radial-gradient;
            background-attachment: fixed;
        }
        &.bg-blue{
            background-color: $bg-blue;
        }
        .video-wrapper {
            position: relative;
        }
        .img-100 {
            width: 100%;
            height: auto;
        }
        img {
            &.mx-auto {
                display: block;
            }
        }
    }

    $sizes: 10, 20, 40, 60, 80, 100, 120, 140;

    @each $size in $sizes {
        .mb-#{$size} {
            margin-bottom: #{$size}px !important;
        }
        .mt-#{$size} {
            margin-top: #{$size}px !important;
        }
    }

}

.offcanvas-body {
    padding: 30px;
}


