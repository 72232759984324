form {
    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
    /* placeholders*/
    ::-webkit-input-placeholder { /* Edge */

    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */

    }

    ::placeholder {

    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: none !important;
        background: transparent;
    }
    
    /* clear focus */
    // outlines (bad)
    input, textarea {
        width: 100%;
        border-radius: 4px;
        @include font(16, 30);
        border-width: 1px !important;
        padding-left: 5px;
        &:focus {
            outline: none;
            text-decoration: none;
            box-shadow: none !important;
        }
    }
    input[type=date],
    input[type=datetime-local],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week] {

    }

    textarea {
        min-height: 80px;
    }

    label {
        display: block;
        color: $black;
        margin-top: 16px;
        margin-bottom: 4px;
        @include font(16, 24);
        font-family: $font-bold;
        &.required {
            padding-right: 3px;
            &:after {
                content: "*";
                color: $red;
            }
        }
        &.mandatory {
            @include font(14, 20);
            padding-left: 3px;
            &:after {
                display: none;
            }
            &:before {
                content: "*";
                display: inline-block;
                padding-right: 2px;
                color: $red;
            }
        }
    }

    .form-header-group {
        h2 {
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    [type=button],
    [type=submit] {
        display: block;
        width: 100%;
        border: none;
        color: $white;
        margin-top: 40px;
        border-radius: 20px;
        @include font(24, 56);
        font-family: $font-bold;
        background-color: $red;
    }


}
