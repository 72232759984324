.btn-base {
    @include font(15, 18, -0.10);
    padding: 8px 15px;
    font-family: $font-bold;
    color: $black;
    @media (min-width: $mobile-limit) {
        @include font(15, 18, -0.10);
        padding: 10px 30px;
    }
    border-radius: $border-radius;
    transition: all $transition-params;
    border: 1px solid transparent;
}

.discover-btn {
    margin-top: 16px;
    margin-bottom:12px;
    padding: 16px 24px;
    border: 2px solid black;
    border-radius: 100px;
    display: block;
    color: $white;
    @include font(14, 14);
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    background: $black;
    cursor: pointer;
    @media (min-width: $mobile-limit) {
        display: inline-block;
        margin-top: 32px;
        margin-bottom:24px;
        padding: 24px 32px;
        @include font(18, 18);
    }
    &:hover {
        color: $white;
    }
}

.btn {
    & + .btn {
        margin-left: 30px;
    }
    &.mx-auto {
        & + .btn {
            margin-top: 20px;
            margin-left: 0;
        }
    }
    &.btn-primary {
        @extend .btn-base;
        background: transparent linear-gradient(180deg, #F5F5F5 0%, #E3E3E3 100%) 0% 0%;
        box-shadow: 0 1px 1px rgba(0,0,0,0.25);
        border-radius: 5px;
        border: 1px solid transparent;
        padding: 9px 0 11px;
        width: 270px;
        display: block;
        transition: all $transition-params;
        color: $text-color;
        @include font(16, 18, -0.10);
        &:hover {
            color: #1E2021;
            background: transparent linear-gradient(180deg, #E2E3E5 0%, #CDCED0 100%) 0% 0%;
        }
        &:active {
            color: #CDD1D4;
            background: transparent linear-gradient(180deg, #59646B 0%, #3B464C 100%) 0% 0%;
        }
        &:focus {
            color: #1E2021;
            border: 1px solid #3F5B93;
        }
        &.medium {
            @include font(15, 20);
            padding: 6px 20px 8px;
        }
    }
    &.btn-secondary {
        @extend .btn-base;
        background: $primary;
        color: $white !important;

        &:hover {
            border-color: $primary !important;
            background-color: transparent;
            color: $primary !important;
        }
    }

    &.btn-secondary {

    }
}
