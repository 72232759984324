header.main-header {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    z-index: 10;
}

.join-us {
    position: fixed;
    left: 50%;
    bottom: 24px;
    border: 2px solid $white;
    border-radius: 40px;
    font-family: $font-bold;
    @include font(20, 28);
    color: $white;
    background-color: $red;
    padding: 16px 50px 14px 50px;
    transform: translateX(-50%);
    z-index: 10;
    min-width: calc(100% - 30px);
    @media (min-width: $mobile-limit) {
        left: auto;
        right: 20px;
        bottom: 38px;
        padding: 20px 63px 18px 63px;
        @include font(24, 32);
        transform: translateX(0%);
        min-width: auto;
    }
}
