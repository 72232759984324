.hide-scrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }
}
.gsap {
    opacity: 0;
    visibility: hidden;
}
/**
 * basic ul styles. Do not override default ul, they will be used in user content
 */
.cleanList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
