a {
    &.id_embedVideo {
        $btn-size: 135px;
        position: absolute;
        transform-origin: center center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $btn-size;
        height: $btn-size;
        z-index: 100;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='135.583' height='135.583' viewBox='0 0 135.583 135.583'%3E%3Cpath id='Subtraction_4' data-name='Subtraction 4' d='M10232.791,16360.585a67.466,67.466,0,0,1-37.9-11.578,67.974,67.974,0,0,1-24.561-29.827,67.706,67.706,0,0,1,6.25-64.29,67.981,67.981,0,0,1,29.826-24.561,67.711,67.711,0,0,1,64.291,6.25,68,68,0,0,1,24.562,29.826,67.7,67.7,0,0,1-6.251,64.291,67.99,67.99,0,0,1-29.827,24.562A67.367,67.367,0,0,1,10232.791,16360.585Zm-20.377-102.88a3.01,3.01,0,0,0-3.007,3.006v64.058a3.009,3.009,0,0,0,3.006,3,2.975,2.975,0,0,0,1.49-.4l55.755-32.03a3,3,0,0,0,0-5.2l-55.755-32.03A2.949,2.949,0,0,0,10212.414,16257.705Z' transform='translate(-10165.002 -16225.002)' fill='%23fff'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat no-repeat;
        background-position: center center;
        background-size: $btn-size;
        cursor: pointer;
        transition: opacity $transition-params;
        filter: drop-shadow(0 30px 20px rgba(0, 0, 0, 0.25));
        &:hover {
            opacity: 0.8;
        }
    }
    &.backToTop {
        position: absolute;
        right: 15px;
        top: 0;
        text-decoration: none;
        .icon {
            &::before {
                color: $white;
                font-size: 28px;
            }
        }
    }
}

