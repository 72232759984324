footer#main-footer {
    height: 176px;
    background-color: #E2261C;
    @media (min-width: $mobile-limit) {
        height: auto;
    }

    h2 {
        text-align: center;
        font-family: $font-titles;
        @include font(28, 36);
        color: $white;
        padding-top: 32px;
        padding-bottom: 0;
        @media (min-width: $mobile-limit) {
            text-align: left;
            @include font(40, 160);
            padding-top: 0;
        }
    }
}
