/**
 * Variables override, see: https://bootstrap.build/app
 */

// Settings
$enable-rounded: false;
$enable-responsive-font-sizes: false;
$enable-shadows: true;


// paths
$img-src-root: '../img';
//$font-src-root: '/assets/src/fonts';
$font-src-root: '../fonts';

// fonts
$font-ultra: "AmsiProCondUltra", Helvetica, sans-serif;
$font-light: "AmsiProLight", Helvetica, sans-serif;
$font-bold: "AmsiProSemiBold", Helvetica, sans-serif;
$font-regular: "AmsiProLight", Helvetica, sans-serif;

$font-body: $font-light;
$font-titles: $font-ultra;


// colors:
$primary: #353F42;
$secondary: #3F5B93;
$white: #fff;
$black: #272727;
$red: #E2261C;
$text-color: #353F42;
$frame-backgroud-light: #f8f8f8;
$frame-backgroud-dark: $black;
$border-color: #D8D8D8;
$header-bg-color: rgba(244, 245, 246, 0.8);
$main-nav-link-color:$text-color;
$main-nav-link-bgcolor-hover:$white;
$main-nav-link-bgcolor-active:#E6E8EA;

$blue-card-header-color: #1B2F5E;

$subnav-link-bgcolor-hover: #858F96;
$subnav-link-bgcolor-active: #353F42;
$subnav-link-color: #F4F5F6;
$subnav-link-color-hover: #CDD1D4;

$bg-grey: #F7F7F4;
$bg-light-grey: #F4F5F6;
$bg-dark-grey: #495860;
$bg-gradient-grey: transparent linear-gradient(180deg, #495860 0%, #1C1D1E 100%) 0% 0% no-repeat padding-box;
$bg-blue: $secondary;
$bg-radial-gradient: transparent radial-gradient(closest-side at 50% 50%, #5D6F9D 0%, #395184 42%, #1A2234 100%) 0% 0% no-repeat;
$text-grey: $subnav-link-bgcolor-hover;

// responsive
$sm-limit: 576px;
$md-limit: 768px;
$lg-limit: 992px;
$xl-limit: 1200px;
$xxl-limit: 1400px;

// set "mobile" limit once and for all your project for easy responsive, or use more precise breakpoints
$mobile-limit: $lg-limit;

// css vars
$main-header-height: 60px;
$full-header-height: 100px;
$bar-max-width: 600px;


// dimensions
$grid-gutter-width: 30px !default;
$button-border-radius: 8px;
$border-radius: 5px;
$bottom-header-height: $full-header-height - $main-header-height;
$section-padding-top-mobile: 40px;
$section-padding-bottom-mobile: 60px;
$section-padding-top: 120px;
$section-padding-bottom: 80px;



// frame paddings
$frame-padding-top-default: 160px;
$frame-padding-bottom-default:$frame-padding-top-default;
$frame-mobile-padding-top-default: 80px;
$frame-mobile-padding-bottom-default: $frame-mobile-padding-top-default;
$section-frame-inner-padding-bottom-default: 80px;
$section-frame-inner-padding-top-default: $section-frame-inner-padding-bottom-default;
$section-frame-inner-mobile-padding-bottom-default: 40px;
$section-frame-inner-mobile-padding-top-default: $section-frame-inner-mobile-padding-bottom-default;

// transitions
$transition-ease: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$short-transition-duration: 0.2s;
$transition-duration: 0.4s;
$long-transition-duration: 8s;
$transition-params: $transition-duration $transition-ease;
$short-transition-params: $short-transition-duration $transition-ease;
$long-transition-params: $long-transition-duration $transition-ease;
$very-long-animation-duration: 2.5s;


/*
 BS breakpoints & containers, override if needed
 */

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
) !default;
// scss-docs-end container-max-widths
