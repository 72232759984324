.offcanvas {
    overflow: auto;
    .offcanvas-header {
        flex-wrap: wrap;
        padding: 64px 15px 48px 15px;
        background-color: $red;
        @media (min-width: $mobile-limit) {
            padding: 64px 30px 48px 30px;
        }
        p {
            color: $white;
            @include font(24, 32);
            margin-bottom: 0;
        }
    }

    .offcanvas-body {
        overflow-y: initial;
    }

    &.offcanvas-end {
        border-left: 0;
    }

    .btn-close {
        position: fixed;
        width: 48px;
        height: 48px;
        top: 25px;
        right: 25px;
        opacity: 1;
        padding: 0;
        background-image: url('#{$img-src-root}/icons/close.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: rotate(0deg);
        transition: transform $transition-params;
        &:hover {
            transform: rotate(360deg);
        }
        &:focus {
            box-shadow: none;
        }
        @media (min-width: $mobile-limit) {
            top: 32px;
            right: 30px;
        }
    }
}

.offcanvas-backdrop {
    background-color: $black;
    &.show {
        opacity: 0.72;
    }
}