:root {
    --main-header-height: #{$main-header-height};
    --full-header-height: #{$full-header-height};
    --mobile-limit: #{$mobile-limit};
    --bar-max-width: #{$bar-max-width};
    .row {
        --bs-gutter-x: #{$grid-gutter-width};
    }
}

