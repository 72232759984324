.carousel{
    padding-bottom: 48px;
    margin-top: 16px;
    margin-bottom: 50px;
    @media (min-width: $mobile-limit) {
        margin-top: 48px;
        padding-bottom: 80px;
    }
    &.carousel-2 {
        .flkty-item {
            --cardgroup-columns: 2;
            --cardgroup-gap: #{$grid-gutter-width};
        }
    }
    &.carousel-3 {
        .flkty-item {
            --cardgroup-columns: 2.5;
            --cardgroup-gap: #{$grid-gutter-width};
        }
    }
    .flkty-item {
        margin-bottom: 0;
        width: 80%;
        margin-right: $grid-gutter-width;
        @include gsap();
        transition: opacity $transition-params;
        @media (min-width: $mobile-limit) {
            width: calc(100%/var(--cardgroup-columns) - (var(--cardgroup-gap)*(var(--cardgroup-columns) - 1))/var(--cardgroup-columns));
        }

        &:hover {
            opacity: 0.8 !important;
        }

        a.id_embedVideo {
            /*
            left: 0;
            top: 0;
            width: 100%;
            height: calc(100% + 80px);
            transform: translate(0,0);
            */
            position: relative;
            display: block;
            width: 100%;
            height: 80px;
            top: 0;
            left: 0;
            margin-top: -35px;
            transform: translate(0,0);
            filter: drop-shadow(0 30px 20px rgba(0, 0, 0, 0));
            background-size: 56px;
            background-position: top center;
            background-image: url('#{$img-src-root}/icons/play.svg');

            .name,
            .position {
                display: block;
                width: 100%;
                padding-left: 0;
                text-align: left;
                float: left;
                clear: both;
            }
            .name {
                margin-top: 60px;
                font-family: $font-titles;
                @include font(24, 32);
            }
            .position {
                font-family: $font-bold;
                @include font(16, 24);
            }

            @media (min-width: $mobile-limit) {
                margin-top: 0;
                background-position: bottom left;
                .name,
                .position {
                    width: auto;
                    padding-left: 71px;
                }
                .name {
                    margin-top: 24px;
                }
            }
        }
    }

    .flickity-viewport {
        overflow: visible;
    }

    .flickity-button {
        width: 40px;
        height: 40px;
        &,
        &:hover {
            background: #353F42;
        }
        &.next,
        &.previous {
            border: 1px solid $primary;
        }
        &.next {
            @media (min-width: $mobile-limit) {
                right: -80px;
            }
            z-index: 4;
            @media (max-width: ($mobile-limit - 1)) {
                right: -20px;
            }

        }
        &.previous {
            @media (min-width: $mobile-limit) {
                left: -80px;
            }
            z-index: 3;
            @media (max-width: ($mobile-limit - 1)) {
                left: -20px;
            }
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:disabled {
            opacity: .4;
        }
        .flickity-button-icon {
            fill: white;
            transform: scale(0.6)
        }
    }
    .flickity-page-dots {
        bottom: 0;
        //display: none;
        .flickity-page-dot {
            display: block;
            width: 30px;
            height: 4px;
            padding: 0;
            margin: 0 1px;
            background: hsl(0 0% 20% / 16%);
            border-radius: 0;
            cursor: pointer;
            appearance: none;
            border: none;
            text-indent: -9999px;
            margin-bottom: 10px;
            overflow: hidden;
            &:focus {
                box-shadow: none;
            }
            &.is-selected {
                background: $red !important;
            }
            @media (min-width: $mobile-limit) {
                width: 60px;
                margin-bottom: 0;
            }
        }
    }
}

section.intro {
    .carousel {

    }
}
