body {
    p {
        font-family: $font-body;
        @include font(20, 32);
        color: $black;
        padding-bottom: 40px;
        @include gsap();
        &.s2 {
            font-family: $font-bold;
            @include font(24, 36);
        }
        &:last-child {
            padding-bottom: 0;
        }
        @media (min-width: $mobile-limit) {
            padding-bottom: 48px;
            @include font(24, 40);
            &.s2 {
                @include font(32, 48);
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-titles;
    span {
        display: block;
        clear: both;
        @include gsap();
        &.black {
            color: $black;
        }
        &.red {
            color: $red;
        }
    }
}

h1,
h2.h1,
h3.h1,
h4.h1,
h5.h1,
h6.h1 {
    font-family: $font-titles;
    margin: 0;
    overflow: hidden;
    padding-bottom: 40px;
    span {
        text-transform: uppercase;
        @include font(48, 48);
        @include gsap();
    }
    @media (min-width: $mobile-limit) {
        padding-bottom: 48px;
        span {
            @include font(96, 96);
        }
    }
}

h2,
h1.h2,
h3.h2,
h4.h2,
h5.h2,
h6.h2 {
    font-family: $font-titles;
    margin: 0;
    overflow: hidden;
    padding-bottom: 32px;
    span {
        text-transform: uppercase;
        @include font(40, 48);
        @include gsap();
    }
    @media (min-width: $mobile-limit) {
        span {
            @include font(64, 64);
        }
    }
}

h3,
h1.h3,
h2.h3,
h4.h3,
h5.h3,
h6.h3 {
    font-family: $font-titles;
    margin: 0;
    padding-bottom: 16px;
    color: $white;
    @include font(40, 48);
}

h4,
h1.h4,
h2.h4,
h3.h4,
h5.h4,
h6.h5 {

}

h5,
h1.h5,
h2.h5,
h3.h5,
h4.h5,
h6{

}

h6,
h1.h6,
h2.h6,
h3.h6,
h4.h6,
h5.h6 {

}

strong {
    font-family: $font-bold;
}

