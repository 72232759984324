@mixin hideScrollBars() {
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }
}


/// Adds ... and breaks text after the given $lines
@mixin ellipsis($lines: 1, $line-height: 1.2em) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    line-height: $line-height;
    max-height: $lines*$line-height;
}

// font mixins
// set fonts to REM and provide fallback for old browsers / devs
// does not work if named font-size...
@mixin fontSize($sizeValue: 12, $important: false) {
    @if ($important) {
        font-size: ($sizeValue) + px !important;
        font-size: (0.0625 * $sizeValue) + rem !important;
    } @else {
        font-size: ($sizeValue) + px;
        font-size: (0.0625 * $sizeValue) + rem;
    }

}

@mixin lineHeight($heightValue: 20, $important: false ){
    @if ($important) {
        line-height: $heightValue + px !important;
        line-height: (0.0625 * $heightValue) + rem !important;
    } @else {
        line-height: $heightValue + px;
        line-height: (0.0625 * $heightValue) + rem;
    }

}
@mixin letterSpacing($value: 20, $important: false ){
    @if ($important) {
        letter-spacing: $value + px !important;
        letter-spacing: (0.0625 * $value) + rem !important;
    } @else {
        letter-spacing: $value + px;
        letter-spacing: (0.0625 * $value) + rem;
    }

}


@mixin font($font-size, $line-height: null, $letter-spacing: null, $important: false) {
    @include fontSize($font-size, $important);
    @if($line-height) {
        @include lineHeight($line-height, $important);
    }
    @if($letter-spacing) {
        @include letterSpacing($letter-spacing, $important);
    }
}

/**
 CHEVRON MIXIN
 @param $position - position of the chevron (left or right of the element), also used for padding
 @param $direction - direction of the chevron (top/right/bottom/left)
 @param $padding - padding to add for chevron in pixels
 @param $stroke-width - stroke width in pixels
 @param $width - chevron width in pixels
 @param $height - chevron height in pixels
 @param $color - chevron color in hex
 @include chevron($position: left, $direction: right, $padding: 30px, $stroke-width: 2px, $width: 8px, $height: 8px, $color: $text-color);
 */

@mixin chevron($position, $direction, $padding, $stroke-width, $width, $height, $color) {
    position: relative;
    padding-#{$position}: $padding;
    &::before {
        #{$position}: 2px;
        border-style: solid;
        border-width: $stroke-width $stroke-width 0 0;
        border-color: $color;
        position: absolute;
        content: '';
        display: inline-block;
        width: $width;
        height: $height;
        transform-origin: 50% 50%;
        transition: all $transition-duration;

        @if $direction == right {
            top: 4px;
            transform: rotate(45deg);
        } @else if ($direction == left) {
            top: 5px;
            transform: rotate(225deg);
        } @else if ($direction == bottom) {
            top: 3px;
            transform: rotate(135deg);
        } @else if ($direction == top) {
            top: 6px;
            transform: rotate(315deg);
        }
    }
}
@mixin gsap() {
    @media screen {
        opacity: 0;
        visibility: hidden;
    }
}

@mixin redDot() {
    position: relative;
    &::before {
        content: "\2022";
        color: #ED3E33;
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        position: absolute;
        left: 0;
    }
}
@mixin multilinebg($changeColor: false) {
    font-family: $font-regular;
    margin-bottom: 29px;
    span {
        display: inline;
        color: #CDD1D4;
        padding: 0;
        box-decoration-break: clone;
        position: relative;
        @media (min-width: $mobile-limit) {
            background: rgba(40,54,80, 0.8);
            box-shadow: 10px 0 0 rgba(40,54,80, 0.8), -10px 0 0 rgba(40,54,80, 0.8);
            @include bgblur();
        }
        @if ($changeColor) {
            &:first-child {
                color: #A8C2F7;
            }
            &:nth-child(2) {
                color: $white;
            }
            &:nth-child(3) {
                color: #B6BDC1;
            }
        }

    }
}
@mixin bgblur($br: false) {
    &::after {
        @if ($br) {
            border-radius: $border-radius;
        }
        content: '';
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: -1;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

/**
 * basic ul styles. Do not override default ul, they will be used in user content
 */
@mixin cleanList() {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
