
@font-face {
	font-family: 'AmsiProLight';
	src: url('#{$font-src-root}/AmsiPro-Light.eot');
	src: url('#{$font-src-root}/AmsiPro-Light.eot?#iefix') format('embedded-opentype'),
	url('#{$font-src-root}/AmsiPro-Light.woff2') format('woff2'),
	url('#{$font-src-root}/AmsiPro-Light.woff') format('woff'),
	url('#{$font-src-root}/AmsiPro-Light.ttf') format('truetype'),
	url('#{$font-src-root}/AmsiPro-Light.svg#AmsiPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AmsiProSemiBold';
	src: url('#{$font-src-root}/AmsiPro-SemiBold.eot');
	src: url('#{$font-src-root}/AmsiPro-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('#{$font-src-root}/AmsiPro-SemiBold.woff2') format('woff2'),
	url('#{$font-src-root}/AmsiPro-SemiBold.woff') format('woff'),
	url('#{$font-src-root}/AmsiPro-SemiBold.ttf') format('truetype'),
	url('#{$font-src-root}/AmsiPro-SemiBold.svg#AmsiPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AmsiProCondUltra';
	src: url('#{$font-src-root}/AmsiProCond-Ultra.eot');
	src: url('#{$font-src-root}/AmsiProCond-Ultra.eot?#iefix') format('embedded-opentype'),
	url('#{$font-src-root}/AmsiProCond-Ultra.woff2') format('woff2'),
	url('#{$font-src-root}/AmsiProCond-Ultra.woff') format('woff'),
	url('#{$font-src-root}/AmsiProCond-Ultra.ttf') format('truetype'),
	url('#{$font-src-root}/AmsiProCond-Ultra.svg#AmsiProCond-Ultra') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}