body {
    section.intro {
        position: relative;
        display: flex;
        align-content: center;
        height: 50vh;
        background-color: #000000;
        background-image: url('#{$img-src-root}/banner_2024.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover !important;
        z-index: 1;
        @include gsap();
        @media (min-width: $mobile-limit) {
            height: 60vh;
            // background-position: top;
            // background-attachment: fixed;
            // background-size: 100% !important;
        }

        .row {
            display: flex;
            align-content: space-between;
            height: 100%;
        }

        .logo {
            position: relative;
            width: 252px;
            height: 88px;
            margin: 40px auto 0 auto;
            background-image: url('#{$img-src-root}/logo.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @include gsap();
        }

        .btns-container {
            display: flex;
            justify-content: center;
        }

        .play-btn {
            top: 0;
            position: relative;
            @include gsap();
            animation:brath 2s  linear infinite;

          a.id_embedVideo {
              background: transparent;
              text-decoration: none;
              display: flex;
              flex-direction: column;
              height: auto;
              justify-content: center;
              width: auto;
              top: 0;
              left: 0;
              position: relative;
              transform: none;

              .icon {
                  position: relative;
                  display: block;
                  width: 88px;
                  height: 88px;
                  top: 0;
                  left: 0;
                  transform: translate(0, 0);
                  filter: drop-shadow(0 30px 20px rgba(0, 0, 0, 0));
                  text-decoration: none;
                  background-size: 88px;
                  background-position: bottom left;
                  background-image: url('#{$img-src-root}/icons/play.svg');

                  @media (min-width: $mobile-limit) {
                      width: 120px;
                      height: 120px;
                      background-size: 120px;
                  }
              }

              span {
                  display: block;
                  position: relative;
                  margin-top: 10px;
                  text-align: center;
                  color: $white;
                  font-family: $font-titles;
                  @include font(16, 24);
                  @media (min-width: $mobile-limit) {
                      @include font(24, 32);
                  }
              }
            }
        }

        .lang-toggler {
            position: relative;
            width: 80px;
            height: 40px;
            top: 0;
            background: $black;
            display: block;
            border-radius: 20px;
            margin-bottom: 5px;
            @include gsap();

          .lang-btn {
              position: absolute;
              top: 2px;
              left: 2px;
              width: 36px;
              height: 36px;
              color: $white;
              background: $black;
              border-radius: 36px;
              font-family: $font-bold;
              @include font(14, 36);
              text-align: center;
              cursor: pointer;
              text-decoration: none;
              transition: 0.3s;

              &:last-child {
                left: auto;
                right: 2px;
              }

              &:hover:not(.active) {
                background: $gray-600;
              }

              &.active {
                background: $red;
              }
          }
        }
    }
}

@keyframes brath{
    0%{transform: scale(.9);}
    25%{transform: scale(1);}
    50%{transform: scale(.9);}
    75%{transform: scale(1);}
    100%{transform: scale(.9);}
}
